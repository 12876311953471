export const hardcodedData = [
  {
    orderIndex: 0,
    brand: "16/21 Машека МАЗ",
    driver: "Сенькевич О.В",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АС 7703-7",
    price: "",
  },
  {
    orderIndex: 1,
    brand: "20/21 Машека МАЗ",
    driver: "Дежурный А.Г.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АР 7719-7",
    price: "",
  },
  {
    orderIndex: 2,
    brand: "25/28 Клинцы МАЗ",
    driver: "Сиплмвый В.Л.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АН 4615-7",
    price: "",
  },
  {
    orderIndex: 3,
    brand: "25/28 Клинцы МАЗ",
    driver: "Назаров Е.Н.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АН 6449-7",
    price: "",
  },
  {
    orderIndex: 4,
    brand: "25/28 Клинцы МАЗ",
    driver: "Кирик М.Н.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АМ 8900-7",
    price: "",
  },
  {
    orderIndex: 5,
    brand: "25/28 Клинцы МАЗ",
    driver: "Белявский С.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АР 7471-7",
    price: "",
  },
  {
    orderIndex: 6,
    brand: "25/28 Клинцы МАЗ",
    driver: "Сенюкович С.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АМ 4687-7",
    price: "",
  },
  {
    orderIndex: 7,
    brand: "25/28 Клинцы МАЗ",
    driver: "Кругликов В.Н.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АО 3813-7",
    price: "",
  },
  {
    orderIndex: 8,
    brand: "25/28 Машека МАЗ",
    driver: "Гинтов О.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АН 9662-7",
    price: "",
  },
  {
    orderIndex: 9,
    brand: "25/28 Машека МАЗ",
    driver: "Шуляк А.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АС 7096-7",
    price: "",
  },
  {
    orderIndex: 10,
    brand: "25/28 Машека МАЗ",
    driver: "Белькович В.К.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АС 5991-7",
    price: "",
  },
  {
    orderIndex: 11,
    brand: "25/28 Машека МАЗ",
    driver: "Железный В.И.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АО 8801-7",
    price: "",
  },
  {
    orderIndex: 12,
    brand: "25/31 Клинцы МАЗ",
    driver: "Михайловский А.А.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АТ 3209-7",
    price: "",
  },
  {
    orderIndex: 13,
    brand: "25/31 Клинцы  МАЗ",
    driver: "Тарабонда В.К",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "ВА 0883-7",
    price: "",
  },
  {
    orderIndex: 14,
    brand: "25/31 Клинцы МАЗ",
    driver: "Демидович К.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АО 2438-7",
    price: "",
  },
  {
    orderIndex: 15,
    brand: "36/40 ZOOMLION",
    driver: "Сочек В.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "BA 3509-7",
    price: "",
  },
  {
    orderIndex: 16,
    brand: "40/40+8 ZOOMLION",
    driver: "Суббота Д.И.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АС 0283-7",
    price: "",
  },
  {
    orderIndex: 17,
    brand: "40/40+8 ZOOMLION",
    driver: "Гарайс М.А.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АС 3724-7",
    price: "",
  },
  {
    orderIndex: 18,
    brand: "40/40+8 ZOOMLION",
    driver: "Устименко В.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АТ 7881-7",
    price: "",
  },
  {
    orderIndex: 19,
    brand: "50/38 GROVE",
    driver: "Вышковский Д.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АТ 0168-7",
    price: "",
  },
  {
    orderIndex: 20,
    brand: "55/43+ 16 ZOOMLION",
    driver: "Жерко А.М.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АХ 3791-7",
    price: "",
  },
  {
    orderIndex: 21,
    brand: "55/43+ 16 ZOOMLION",
    driver: "Зенько В.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АХ 5830-7",
    price: "",
  },
  {
    orderIndex: 22,
    brand: "70 тонн XCMG",
    driver: "Гарайс М.А.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "ВА 2819-7",
    price: "",
  },
  {
    orderIndex: 23,
    brand: "80/49+17,5 ZOOMLION",
    driver: "Алексейчик Д.Н.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АХ 6148-7",
    price: "",
  },
  {
    orderIndex: 24,
    brand: "80/49+17,5 ZOOMLION",
    driver: "",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "",
    price: "",
  },
  {
    orderIndex: 25,
    brand: "90/48+16 ZOOMLION",
    driver: "Арсенович И.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АР 9633-7",
    price: "",
  },
  {
    orderIndex: 26,
    brand: "110 - ZOOMLION",
    driver: "Скуратович А.А.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АР 9633-7",
    price: "",
  },
  {
    orderIndex: 27,
    brand: "140 - ZOOMLION",
    driver: "Бунас Р.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "АР 9633-7",
    price: "",
  },
  {
    orderIndex: 28,
    brand: "Гусеница ",
    driver: "Рожков  Е.А.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "",
    price: "",
  },
  {
    orderIndex: 29,
    brand: "XCMG 215 ГУС",
    driver: "Ермак В.Н.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "",
    price: "",
  },
  {
    orderIndex: 30,
    brand: "XCMG 215 ГУС",
    driver: "Дудко Д.П.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "",
    price: "",
  },
  {
    orderIndex: 31,
    brand: "XCMG 215 ГУС",
    driver: "Каток ",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "",
    price: "",
  },
  {
    orderIndex: 32,
    brand: "Хидромек ЭКС- погрузчик ",
    driver: "Абметка Н.С.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "КА-7 6289",
    price: "",
  },
  {
    orderIndex: 33,
    brand: "MST экс погрузчик",
    driver: "Знак Д.М.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "КА-7 8138",
    price: "",
  },
  {
    orderIndex: 34,
    brand: "MST экс погрузчик",
    driver: "Цвирко А.С.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "КА7- 8369",
    price: "",
  },
  {
    orderIndex: 35,
    brand: "MST экс погрузчик",
    driver: "Буримский А.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "КА-7 7877",
    price: "",
  },
  {
    orderIndex: 36,
    brand: "MST экс погрузчик",
    driver: "Гайдукевич А.С.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "КА-7 8369",
    price: "",
  },
  {
    orderIndex: 37,
    brand: "XCMG фронтальный погрузчик",
    driver: "Бужан А.В.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "КА-7 8097",
    price: "",
  },
  {
    orderIndex: 38,
    brand: "XCMG фронтальный погрузчик",
    driver: "Малчанов А.Н",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "КА-7 8310",
    price: "",
  },
  {
    orderIndex: 39,
    brand: "XCMG фронтальный погрузчик",
    driver: "Щербанев М.Л.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "КА-7 7298",
    price: "",
  },
  {
    orderIndex: 40,
    brand: "Щетка МТЗ 82 МК",
    driver: "Чернов Н.А.",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "",
    price: "",
  },
  {
    orderIndex: 41,
    brand: "Трал",
    driver: "Невдах",
    customer: "",
    routeNumber: "",
    hours: "",
    isTrue: false,
    vehicleNumber: "BA-7 2910",
    price: "",
  },
];
